







































































import { InputSetups } from '@/mixins/input-setups'
import EditorButtonsHelpView from '@/includes/helpViews/editorButtonsHelpView.vue'
import Placeholders from '@/mixins/placeholders/placeholders'
import ModuleConfigSettings from '../../components/ModuleConfigSettings.vue'
import ModuleConfigSettingsWrapper from '@/components/Modules/components/ModuleConfigSettingsWrapper.vue'
import SendAdminMessageModuleBuilder
  from '@/includes/logic/Modules/models/modules/SendAdminMessageModule/SendAdminMessageModuleBuilder'
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'

import { MediaEditorMode } from 'piramis-base-components/src/components/NewMessageEditors/types'
import MultiMessageEditorWithMediaInput from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue'
import TimeUnitInput from 'piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue'
import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types'

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  components: {
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    MultiMessageEditorWithMediaInput,
    TimeUnitInput,
  },
  data() {
    return {
      MediaEditorMode,
      EditorButtonsHelpView,
      StepUnit
    }
  },
})
export default class SendAdminMessageModuleView extends Mixins(InputSetups, Placeholders, ModuleBindings) {
  @VModel() module!: SendAdminMessageModuleBuilder

  get hasLicense() {
    return this.$store.getters.isChatAtLeastAdvanced && this.$store.getters.isChatLicenseExists
  }

  get minValue() {
    const { params } = this.module.model

    if (params.admin_message_interval === 0) {
      return 0
    }

    if (this.hasLicense) {
      return 1
    } else {
      return 20
    }
  }
}
